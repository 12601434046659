<template>
  <div id="app">
    <div v-if="!isFramed"><input v-model="geocodeLocation" size="25" placeholder="address, city, country"><button @click="findLocation">Waar is 't?</button></div>
    <div class="container">
      <WalkingHomeMap />
      <InstaPosts v-if="asideOpen" />
      <img v-if="asideOpen" class="imgPlus" @click="toggleAside" :src="iconPlus">
      <img v-if="!asideOpen" class="imgMin" @click="toggleAside" :src="iconMin">
    </div>
  </div>
</template>

<script>
import WalkingHomeMap from './components/WalkingHomeMap.vue'

import InstaPosts from './components/InstaPosts.vue'
import { bus } from './main'

export default {
  name: 'App',
  components: {
    WalkingHomeMap,
    InstaPosts
  },
  data(){
      return {
          isFramed: window !== window.parent ? true : false,
          geocodeLocation: '',
          locInfo: {},
          asideOpen: true,
          iconPlus: require('@/assets/plus.png'),
          iconMin: require('@/assets/minus.png')
      }   
  },
  methods: {
    toggleAside() {
        this.asideOpen = !this.asideOpen;
    },
    findLocation() {

      if (this.geocodeLocation) {
         this.axios
         .get('https://api.traveltimeapp.com/v4/geocoding/search?query=' + this.geocodeLocation, {
                headers: {
                            "Content-Type": "application/json",
                            "X-Application-Id": this.$appid,
                            "X-Api-Key": this.$apikey,
                            "Content-Language": "en-US",
                            "Accept-Language" : "en-US"
                          }
                })
          .then(response => {
            console.log(response);

            var gevonden = response.data.features;

            if (gevonden.length > 0) {
                  this.locInfo = {
                    name: gevonden[0].properties.name,
                    lat: gevonden[0].geometry.coordinates[1],
                    lng: gevonden[0].geometry.coordinates[0]
                  }
                  //alert(this.locInfo.name + ' ' + this.locInfo.lat + ' ' + this.locInfo.lng);

                  bus.$emit('foundLocation', this.locInfo)
            } else {
                  alert('Geen locatie gevonden, Type address, City, Country (En)');
            }
          })
          .catch((response)=>{
            alert(response.message + ' Probeer het nog eens...');
          }
          );
      }
      else {
        alert('type een locatie (= adres, plaats, [land]) in');
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px; 
  width: 100vw;
  padding: 0%;
  margin: 0;
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.imgPlus {
  position: absolute;
  top: 2.2em;
  left: 84.5vw;
  height: 1em;
  z-index: 100;
}
.imgMin {
  position: absolute;
  top: 2.2em;
  left: 95vw;
  height: 1em;
  z-index: 100;
}

</style>
