import Vue from 'vue'
import App from './App.vue'
import 'leaflet/dist/leaflet.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

Vue.prototype.$strapiendpoint =
  location.hostname === "localhost"
    ? "http://localhost:1337/"
    : "https://desolate-scrubland-69343.herokuapp.com/";


Vue.prototype.$weatherendpoint = 'https://api.weatherapi.com/v1/current.json'
Vue.prototype.$weatherapikey = process.env.VUE_APP_WEATHERAPIKEY
Vue.prototype.$appid = process.env.VUE_APP_APPID
Vue.prototype.$apikey = process.env.VUE_APP_APIKEY

Vue.use(VueAxios, axios)

export const bus = new Vue()

new Vue({
  render: h => h(App),
}).$mount('#app')
