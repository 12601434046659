<template>
   <l-map id="myMap" ref="myMap" @ready="doOnReady()" style="height: 100vh;" :zoom="zoom" :center="center">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker  :lat-lng="mlatlng" ></l-marker>
      
      <l-marker id="instaMarker"	
                ref="instaMarker" 
                :lat-lng="insta.ll" @add="$nextTick(() => $event.target.openPopup())">
         <l-icon
            :icon-url="insta.iconUrl"
          >
          </l-icon>
          <l-popup :content="insta.desc"></l-popup>
      </l-marker>

      <l-circle
      :lat-lng="circle.center"
      :radius="circle.radius"
      :color="circle.color"
      />

      <l-marker :id="marker.id" @click="deleteMarker(i, true)" v-for="marker, i in markers" :key="marker.id" :lat-lng="marker.ll" :visible="marker.visible">
        <l-icon id="beenThereIcon"
            v-if="marker.otherIconUrl"
            :icon-url="marker.otherIconUrl"
            class="beenThereIcon"
            >
        </l-icon>
        <l-popup :content="marker.desc" ></l-popup>
      </l-marker>


      <l-marker @click="deleteMarker(i ,false)" v-for="marker, i in plannedMarkers" :key="marker.id" :lat-lng="marker.ll">
          <l-icon id="plannedIcon"
            :icon-url="iconUrl"
          >
          </l-icon>
        <l-popup>{{ marker.desc }}</l-popup>
      </l-marker>

      <l-circle v-for="marker in plannedMarkers" :key="marker.id"
                :lat-lng="marker.ll"
                :radius="circlePlanned.radius"
                :color="circlePlanned.color"
      />
  
       
      <l-polyline :lat-lngs="lineMarkers" :color="lineColor"></l-polyline>
      
       <l-control v-if="!isFramed" class="custom-control" :position="'topright'">
        <p v-if="!went.show">
        <button @click="went.show = !went.show">{{ message }}</button>
        </p>
        <p v-else>
            <input v-model="went.info" :placeholder="went.placeholder"><button @click="showLocation">{{ message }}</button>
        </p>
        <p v-if="!goto.show">
          <button @click="addListenerToMap">{{ planMsg }}</button>
        </p>
        <p v-else>
            <label>Info: </label><input v-model="goto.info" :placeholder="goto.placeholder">
        </p>
        <span>{{ status }}</span>
        
      </l-control>
       <l-control v-if="isFramed || debugging" class="custom-control" :position="'topleft'">
        <h2>
          <img height="50px" :src="userMessage">Journey map - Walking home project
        </h2>
      </l-control>
       <l-control class="custom-control distance" :position="'bottomleft'">
        <p>
          <strong>Jyoti walked {{ jyotiWalked }} km in {{ daysWalked }} days already!</strong>
          <br>Encourage Jyoti on her journey by following her on <a target='_new' href='https://www.instagram.com/the_walking_home_project/' >Insta</a> or mail her on <a href='mailto:jyotiwalking@thewalkinghomeproject.eu'>jyotiwalking[At]thewalkinghomeproject.eu
        </a></p>
      </l-control>
      <l-control class="custom-control" :position="'bottomright'">
        <h3>Insta images</h3>
        <div v-if="doShowBig" class="biggerImage">
            <img :src="getUrl(bigImage)" />&nbsp;&nbsp;
            <button @click="closeBig">Close</button>
        </div>
        <div class="insta-gallery" v-if="instaImages.length > 0">
          <span v-if="nav.page > 0"><a class="up" @click="shiftDown">&lt;&lt;</a>&nbsp;</span>
          <figure @click="showBig(img)" v-for="img, i in instaImages" :key="img">
            <img v-if="i >= nav.page * nav.nr && i < (nav.page * nav.nr) + nav.nr " :src="getUrl(img)" height="65px" />
          </figure>
          <span v-if="(nav.page * nav.nr) + nav.nr < instaImages.length" >&nbsp;<a class="down" @click="shiftUp">&gt;&gt;</a></span>
        </div>
      </l-control>

  </l-map>
</template>
<script>
import { bus } from '../main'
const STARTLL = [50.846938, 4.352593,10];
const CENTERLL = [48.8459354,4.3539903,15];
const STARTZOOM = 10;
const LOCZOOM = 10;
const FRAMED = window !== window.parent ? true : false; 
const PATHCORRECTION = 1.66;
const NUMBERTHUMBS = 4;
const AMOUNTREQUESTED= 50;
const DEBUG = (window.location.href.indexOf("localhost") >= 0) ? true : false; 

const Msg = {
  WhereAmI: 'Waar ben ik nu? Zet t op de kaart.',
  Found: 'Locatie gevonden, Toevoegen aan je geschiedenis?',
  NotFound: 'Locatie niet gevonden, ververs je pagina.',
  Empty: '',
  Busy: 'Bezig met opslaan...',
  SEEKPOS: 'GPS locatie bepalen...',
  PLAN: 'Waar ga ik nog meer naar toe?',
  STOPPLAN: 'Stop met nieuwe locaties toevoegen',
  PLANMSG: 'Klik op de kaart waar je naar toe wil gaan, en waar je iets zoekt',
  ZOEK: 'I am looking here or in the surroundings for a place to stay or interesting places or people to visit. Donot hesitate to contact me',
  SUCCES: 'Succesvol verwerkt',
  //WAIT: 'Please wait for the markers to load..',
  GOTO: 'Vlaggetje die je op de kaart wilt zetten',
  WENT: 'Omschrijf de plek waar je nu bent'
}


import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LControl, LPolyline,LPopup, LIcon, LCircle } from 'vue2-leaflet';
//import { LMap, LTileLayer, LMarker, LControl, LPopup, LIcon, LCircle } from 'vue2-leaflet';
import { Icon } from 'leaflet';

String.prototype.format = function () {
  var args = arguments;
  return this.replace(/{([0-9]+)}/g, function (match, index) {
    // check if the argument is there
    return typeof args[index] == 'undefined' ? match : args[index];
  });
};

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'WalkingHomeMap',
   components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LPolyline,
    LPopup,
    LIcon,
    LCircle
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: STARTZOOM,
      center: CENTERLL,
      map: null,
      mlatlng: STARTLL,
      allPlaces: [],
      markers: [],
      start: 0,
      bufferedMarkers: [],
      lineMarkers: [],
      plannedMarkers: [],
      lineColor: 'green',
      message: Msg.WhereAmI,
      askJN: false,
      isFramed: FRAMED,
      iconUrl: require('@/assets/greenmarker.png'),
      iconNotWalkedUrl: require('@/assets/funnycar.png'),
      insta: { 
        iconUrl: require('@/assets/insta.svg'),
        ll: CENTERLL,
        desc: 'Initial center of map'
       },
      planMsg: Msg.PLAN,
      status: '',
      jyotiWalked: 0,
      daysWalked: 0,
      userMessage: require('@/assets/loading.png'),
      goto: { info: '', show: false, placeholder: Msg.GOTO },
      went: { info: '', show: false, placeholder: Msg.WENT },
      circle: {
        center: [0,0],
        radius: 4500,
        color: 'red'
      },
      circlePlanned: {
        center: CENTERLL,
        radius: 10000,
        color: 'green'
      },
      instaImages: [],
      doShowBig : false,
      bigImage: '',
      nav: {
        page: 0,
        nr: NUMBERTHUMBS,
      },
      debugging: DEBUG
    };
  },
  beforeUpdate() {
    this.start = this.getMarkersFromLocalStorage();
  },
  mounted() {

    bus.$on('foundLocation', (locInfo) => { 
          //alert("In Walking Home " + locInfo.name);
          var fndplace = L.latLng(locInfo.lat, locInfo.lng);
          this.circle.center = [locInfo.lat, locInfo.lng];
          this.circle.radius = 4500;
          this.animateCircle();
          this.map.panTo(fndplace);
    }); 

    bus.$on('setInstaMarker', (locInsta) => {
      //console.log(locInsta);
      this.instaImages = locInsta.images;
      this.insta.desc = "<p>"+ locInsta.desc + "<br><a target='_new' href='"+ locInsta.url  +"'>Insta</a></p>";
      console.log(this.insta.desc);
      if (locInsta.lat > 0) {
        this.insta.ll = [locInsta.lat, locInsta.lng];
        var fndplace = L.latLng(locInsta.lat, locInsta.lng);
        this.$refs.instaMarker.mapObject.openPopup();
        this.map.panTo(fndplace);
      }
      else {
        this.map.closePopup();
      }
      
      this.map.on('click', this.clearImages);

    })
    //recursief totdat alles is opgehaald, 50 per keer
    // vanaf 0 of vanaf laatste opgehaalde start (als er al iets is)
    this.getPlacesFrom(this.start);
  },
   methods: {
    getPlacesFrom(start) {     
      //per 50 ophalen      
      var pageQuery = `?_start=${start}&_limit=`+AMOUNTREQUESTED+`&_sort=CreatedAt`;
      this.axios
         .get(this.$strapiendpoint + "been-there-places" + pageQuery, {
        })
        .then(result => {
          if (result.data && result.data.length > 0) {         

            //var prevplace = null;
            const vandaag = new Date();
            const startdag = new Date('07/07/2022');

            this.daysWalked = Math.round((vandaag.getTime() - startdag.getTime()) / (1000 * 3600 * 24));
            result.data.forEach((place,i) => {
              this.say('Found ' + (i+1));
              //toevoegen aan markers of plannedMarkers
              setTimeout(() => {
                   //console.log(place.desc + " " + place.id);
                   this.addMarker({ ll: [place.latitude,place.longitude], desc: place.desc, id: place.id }, place.beenThere, place.weatherjson === undefined ? null : place.weatherjson, true); //laatste par zegt buffer ja
              }, 100);
              
              if (i && place.beenThere) {
                  var curplace = L.latLng(place.latitude, place.longitude);
                  this.map.panTo(curplace);

                  if (place.desc.indexOf('DIDNOTWALKTO') == -1) {
                    //this.jyotiWalked += Math.round (PATHCORRECTION * curplace.distanceTo(prevplace)/1000);
                  }
                  //console.log( i + ') Afstand afgelegd ' + this.jyotiWalked);
              } 
              if (place.beenThere) {
                //prevplace = L.latLng(place.latitude, place.longitude);
              }
            });

            this.userMessage = '';
            setTimeout(() => {
                                this.getPlacesFrom(start+AMOUNTREQUESTED);
                        },1000);
          }
          else {
            // start heeft nu de waarde waarbij niets meer gevonden wordt
            this.flushBuffer();
            // na flushbuffer is this.markers pas gevuld
            this.saveMarkersLocalStorage(start);
            this.calcTotalDistance();
          }
        });

    },
    // bereken totale afstand
    calcTotalDistance() {
      var prevplace = null;
      var total = 0;
      this.markers.forEach((place,i) => {
        if (i && place.desc.indexOf('DIDNOTWALKTO') == -1) {
          var curplace = L.latLng(place.ll[0], place.ll[1]);
          total += Math.round (PATHCORRECTION * curplace.distanceTo(prevplace)/1000);
        }
        prevplace = L.latLng(place.ll[0], place.ll[1]);
      });
      this.jyotiWalked = total;
    },
    shiftDown() {
        this.nav.page--;
    },
    shiftUp() {
        this.nav.page++;
    },
    clearImages() {
        this.instaImages = [];
        this.map.off('click', this.clearImages);
        this.doShowBig = false;
    },
    showBig(img) {
        this.doShowBig = true;
        this.bigImage = img;
    },
    closeBig(){
        this.doShowBig = false;
    },
    getUrl(img) {
        return "/insta/" + img;
    },
    animateCircle () {
          if (this.circle.radius > 0) {
            this.circle.radius -= 50;
            setTimeout(this.animateCircle, 100);
          }
    },
    deleteMarker(i, beenThere) {
      const mrk = beenThere ? this.markers[i] : this.plannedMarkers[i];
      var mayDelete = beenThere ? i >= (this.markers.length - 1) : i >= (this.plannedMarkers.length - 1)
      if (!this.isFramed) {
        if (mayDelete && confirm('Verwijder punt "' + mrk.desc + '" ?')) {
          if (beenThere) { 
            this.markers.splice(i,1);
          }
          else {
            this.plannedMarkers.splice(i,1);
          }
          this.axios
              .delete(this.$strapiendpoint + "been-there-places/" + mrk.id)
              .then(() => {
                this.say(Msg.SUCCES);
              })
              .catch((e) => {
                this.say(e.message);
              });
        }
      }
    },
    showLocation() {
      this.say(Msg.SEEKPOS);
      navigator.geolocation.getCurrentPosition(
         pos => {
            this.map.setZoomAround(L.latLng(pos.coords.latitude, pos.coords.longitude), LOCZOOM);
            var info = this.went.info ? this.currentDateTime() + ': ' + this.went.info  : 'Lokatie bereikt op ' + this.currentDateTime();
            this.addMarker({ll: [pos.coords.latitude, pos.coords.longitude], desc: info, id: 0 });      
            this.say(Msg.Found);    
            this.askJN = true;
            setTimeout(() => { 
                this.saveLocation(true);
                this.went.show = false;
                this.went.info =  ''; 
                }, 2000);

          },
          err => {
            this.gettingLocation = false;
            this.message = Msg.NotFound;
            console.log(err.message);
          }
       );
    },
    doOnReady() {
        //console.log('I am ready');
        this.map = this.$refs.myMap.mapObject; 
        this.map.on('zoomend', () => {
          if (this.map.getZoom() < 8) {
            this.markers.forEach((mrk,i) => {
              //the last marker is always visible
                if ( (i%(10-this.map.getZoom())) == 0 || i == (this.markers.length - 1)) {
                    mrk.visible = true;
                }
                else {
                    mrk.visible = false;
                }
            });
          }
          else {
            this.markers.forEach((mrk) => {
                mrk.visible = true;
            });
          }
        })
    },
    addMarker(m, beenThere = true, weather = null, buffer = false) {
      m.visible = true;
      if (beenThere) {
         if (weather != null) {
            m.desc += "<br><img width='40' src='"+ weather.condition.icon + "' >&nbsp;" + weather.temp_c + "&deg; - wind: " + weather.wind_dir + " " + weather.wind_kph + "kph - rain:" + weather.precip_mm + "mm";
         }
         if (m.desc.indexOf('DIDNOTWALKTO') > -1) {
              m.otherIconUrl = this.iconNotWalkedUrl; 
         }

         if (buffer) {
            //check if marker already exists
            var found = false;  
            this.bufferedMarkers.forEach((mrk) => {
                if (mrk.id == m.id) {
                    found = true;
                }
            });
            if (!found)
              this.bufferedMarkers.push(m);
         } else {
            //check if marker already exists
            found = false;
            this.markers.forEach((mrk) => {
                if (mrk.id == m.id) {
                    found = true;
                }
            });
            if (!found)
              this.markers.push(m);
         }
      }
      else {
        this.plannedMarkers.push(m);
      }
    },
    flushBuffer() {
      /* 
      * 1. copy bufferedMarkers to markers
      * 2. sort markers on date
      * 3. copy markers to lineMarkers
      */
        this.markers = this.bufferedMarkers;
        this.bufferedMarkers = [];
        var sorted =  this.markers.sort((a, b) => {
                      const parseDMY = s => {
                            let [d, m, y, h, min] = s.split(/\D/);
                            return new Date(y, m-1, d, h, min);
                      };
                      var dateA = parseDMY(a.desc.substr(0,16));
                      var dateB = parseDMY(b.desc.substr(0,16));
                       
                      //sorteren op datum
                      if (dateA < dateB) {
                        return -1;
                      }
                      if (dateA > dateB) {
                        return 1;
                      }

                      return 0;
                    });
        this.lineMarkers = sorted.map(x => x.ll);
    },
    saveMarkersLocalStorage(ended) {
      localStorage.setItem('markersEnded', ended);
      localStorage.setItem('markers', JSON.stringify(this.markers));
    },
    /* describe function
    * 1. get markers from localstorage and until what index
    */
    getMarkersFromLocalStorage() {     
      var ended = localStorage.getItem('markersEnded');
      if (ended>0) {
        var dumMarkers = JSON.parse(localStorage.getItem('markers'));
        //console.log(dumMarkers.length + ' markers loaded from localstorage');
        if (dumMarkers.length > 0) {
          dumMarkers.forEach((mrk) => {
            setTimeout(() => {
              this.addMarker(mrk, true);
            }, 10);
          });
          return ended-AMOUNTREQUESTED;
        }
        else {
          return 0;
        }
      }
      else 
        return 0;

    },
    getLastMarker(been) {
        return been ? this.markers[this.markers.length - 1] : this.plannedMarkers[this.plannedMarkers.length - 1];
    },
    //Na opslaan in de backend ook nog id bijwerken, voor mogelijke verwijdering
    updateLastId(id,been) {
      if (been) {
        this.markers[this.markers.length - 1].id = id;
      }
      else {
        this.plannedMarkers[this.plannedMarkers.length - 1].id = id;
      }
    },
    clickedOnMap(event) {
          var info = this.goto.info ? this.goto.info : Msg.ZOEK;
          this.addMarker({ ll: [event.latlng.lat,event.latlng.lng], desc: info, id: 0 }, false);

          setTimeout(() => { 
             this.saveLocation(false);
             this.goto.show = false;
             this.goto.info = '';       
          }, 2000);
          
          this.map.off('click', this.clickedOnMap);
    },
    addListenerToMap() {
          this.goto.show = true;
          alert(Msg.PLANMSG);
          this.map.on('click', this.clickedOnMap);
    },
    currentDateTime() {
      const current = new Date();
      return current.getDate().toString().padStart(2,'0') +'/'+ (current.getMonth()+1).toString().padStart(2,'0') + '/' + current.getFullYear() + ' ' + current.getHours().toString().padStart(2,'0') + ":" + current.getMinutes().toString().padStart(2,'0');
      //return current.getDate() +'/'+ (current.getMonth()+1)+ '/' + current.getFullYear() + ' ' + current.getHours().toString().padStart(2,'0') + ":" + current.getMinutes().toString().padStart(2,'0');
      //const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    },
    saveLocation(been = true) {
      const last = this.getLastMarker(been);
      this.say(Msg.Busy);
      this.askJN = false;
            this.axios
            .post(this.$strapiendpoint + "been-there-places", {
              //data: {
              latitude: last.ll[0],
              longitude: last.ll[1],
              desc: last.desc,
              beenThere: been
              //},
              //authorisatie is nog even uitgezet, later nog eens fixen
            })
            .then(response => {
              this.updateLastId(response.data.id, been);
              if (been) {
                this.updateWeatherLastId(response.data.id);
              }
              console.log(response.data.id);
              this.say(Msg.SUCCES);
            })
            .catch(error => {
              this.say(error.message);
            });
    },
    updateWeatherLastId(id) {
        const mrk = this.getLastMarker(true);
        //console.log(id);     
        this.axios.get(this.$weatherendpoint 
                     + '?key=' +  this.$weatherapikey
                     + '&q=' + mrk.ll[0] + ',' + mrk.ll[1]
                     + '&aqi=no')
            .then(response => {
              console.log(response.data);
              var currentWeer = response.data.current;
              this.axios
                    .put(this.$strapiendpoint + "been-there-places/" + id, {
                            'weatherjson': currentWeer
                    })
                    .then(() => {
                        console.log('weer toegevoegd');
                    }) 
                    .catch(error => {
                        console.log(error.message);
                    });
            });
    },
    say(msg) {
        this.status = msg;
        setTimeout(() => { this.status = ''}, 5000);
    },
    resetLastLocation() {
      console.log('reset location');
      this.askJN = false;
      this.markers.pop();
      this.message = Msg.WhereAmI;
      this.resetMap();
    },
    resetMap() {
            this.map.setZoom(STARTZOOM);
            this.map.panTo(L.latLng(CENTERLL[0],CENTERLL[1]));
    }
  }
}
</script>
<style scoped>
#myMap {
  height: 100vh;
  width: 83vw;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  #myMap {
    height: 100vh;
    width: 70vw;
    margin: 0;
  }
}

  

.insta-gallery {
 display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}

.insta-gallery figure {
  margin: 0.2em;
}

.insta-gallery span a.up {
  position: relative;
  top: 40px;
  left: 1em;
}

.insta-gallery span a.down {
  position: relative;
  top: 40px;
  right: 1em;
}

/* .xinsta-gallery figure img:hover, .insta-gallery figure img:active {*/
.biggerImage {
  padding: 0.5em;
  background: white;
}

.biggerImage img {
  max-width: 20vw;
}

@media only screen and (max-width: 600px) {
  .biggerImage img {
    max-width: 50vw;
  }
}

.custom-control {
  background: #fff;
  padding: 0 0.8em;
  border: 1px solid #aaa;
  border-radius: 0.1em;
}



.custom-control.legenda ul li:nth-child(1)::marker{
  color: blue;
  font-size: 2em;
}
.custom-control.legenda ul li:nth-child(2)::marker{
  color: #61C357;
  font-size: 2em;
}

.custom-control.legenda ul {
  margin: 0;
}

.custom-control.legenda strong {
  display: block;
  text-align: left;
  padding-left: 2em;
}
</style>